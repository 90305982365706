import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

export const LegalPageTemplate = ({ content }) => {
  return (
    <Grid fluid>
      <Col xs={12}>
        <Row>
          <HTMLContent className="content has-link-inline" content={content} />
        </Row>
      </Col>
    </Grid>
  )
}

LegalPageTemplate.propTypes = {
  content: PropTypes.string,
}

const LegalPage = ({ data }) => {
  const { html } = data.markdownRemark
  return (
    <Layout>
      <LegalPageTemplate content={html} />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPageQuery($categoryKey: String) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "legal-page" }, categoryKey: { eq: $categoryKey } }
    ) {
      html
    }
  }
`
